	/*
  	Flaticon icon font: Flaticon
  	Creation date: 18/07/2017 06:33
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-bed-1:before { content: "\f100"; }
.flaticon-maid:before { content: "\f101"; }
.flaticon-drink:before { content: "\f102"; }
.flaticon-sauna:before { content: "\f103"; }
.flaticon-barbecue:before { content: "\f104"; }
.flaticon-power:before { content: "\f105"; }
.flaticon-ethernet:before { content: "\f106"; }
.flaticon-shampoo:before { content: "\f107"; }
.flaticon-smoking:before { content: "\f108"; }
.flaticon-stove:before { content: "\f109"; }
.flaticon-wifi-1:before { content: "\f10a"; }
.flaticon-worldwide:before { content: "\f10b"; }
.flaticon-room-service-1:before { content: "\f10c"; }
.flaticon-door-lock:before { content: "\f10d"; }
.flaticon-double-bed-1:before { content: "\f10e"; }
.flaticon-elevator:before { content: "\f10f"; }
.flaticon-fan:before { content: "\f110"; }
.flaticon-hotel-2:before { content: "\f111"; }
.flaticon-hotel-sign:before { content: "\f112"; }
.flaticon-hotel-1:before { content: "\f113"; }
.flaticon-iron:before { content: "\f114"; }
.flaticon-key:before { content: "\f115"; }
.flaticon-laundry-1:before { content: "\f116"; }
.flaticon-luggage:before { content: "\f117"; }
.flaticon-door-hanger:before { content: "\f118"; }
.flaticon-bathtub:before { content: "\f119"; }
.flaticon-elegant:before { content: "\f11a"; }
.flaticon-nature:before { content: "\f11b"; }
.flaticon-lift:before { content: "\f11c"; }
.flaticon-technology-1:before { content: "\f11d"; }
.flaticon-window:before { content: "\f11e"; }
.flaticon-technology:before { content: "\f11f"; }
.flaticon-bath:before { content: "\f120"; }
.flaticon-health-care:before { content: "\f121"; }
.flaticon-money:before { content: "\f122"; }
.flaticon-air-conditioner:before { content: "\f123"; }
.flaticon-bathrobe:before { content: "\f124"; }
.flaticon-lamp:before { content: "\f125"; }
.flaticon-towel:before { content: "\f126"; }
.flaticon-pool:before { content: "\f127"; }
.flaticon-booking:before { content: "\f128"; }
.flaticon-car:before { content: "\f129"; }
.flaticon-double-bed:before { content: "\f12a"; }
.flaticon-wifi:before { content: "\f12b"; }
.flaticon-gym:before { content: "\f12c"; }
.flaticon-staff:before { content: "\f12d"; }
.flaticon-jacuzzi:before { content: "\f12e"; }
.flaticon-key-card:before { content: "\f12f"; }
.flaticon-laundry:before { content: "\f130"; }
.flaticon-pet:before { content: "\f131"; }
.flaticon-swimming-pool:before { content: "\f132"; }
.flaticon-serve:before { content: "\f133"; }
.flaticon-twin-beds:before { content: "\f134"; }
.flaticon-reception-bell:before { content: "\f135"; }
.flaticon-reception:before { content: "\f136"; }
.flaticon-remote:before { content: "\f137"; }
.flaticon-cutlery:before { content: "\f138"; }
.flaticon-room-service:before { content: "\f139"; }
.flaticon-shower-1:before { content: "\f13a"; }
.flaticon-shower:before { content: "\f13b"; }
.flaticon-bed:before { content: "\f13c"; }
.flaticon-toilet:before { content: "\f13d"; }
.flaticon-towel-1:before { content: "\f13e"; }
.flaticon-hotel:before { content: "\f13f"; }
.flaticon-parking:before { content: "\f140"; }