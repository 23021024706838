/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: none; touch-action: none; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }
.slick-list:focus { outline: none; }
.slick-loading .slick-list { background: white url('ajax-loader.gif') center center no-repeat; }
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; zoom: 1; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }
.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

/* Icons */
@font-face { font-family: "slick"; src: url('fonts/slick.eot'); src: url('fonts/slick.eot?#iefix') format("embedded-opentype"), url('fonts/slick.woff') format("woff"), url('fonts/slick.ttf') format("truetype"), url('fonts/slick.svg#slick') format("svg"); font-weight: normal; font-style: normal; }

/* Arrows */
.slick-prev, .slick-next { display: block; line-height: 0; font-size: 0; cursor: pointer; color: transparent; padding: 0; border: none; outline: none; 
width: 24px;
height: 24px;
margin-top: 3px;
margin-left: 3px;
position: absolute;
cursor: pointer;
background: url('rs-minimal-white.png') transparent;
background-color: #000000;
background-color: rgba(0, 0, 0, 0.75);
border-radius: 2px;

}
.slick-prev:focus, .slick-next:focus { outline: none; }
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before { opacity: 0.25; }

.slick-prev:before, .slick-next:before { font-family: "slick"; font-size: 20px; line-height: 1; color: white; opacity: 0.85; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev { right: 45px; bottom:15px; background-position: -68px -36px;}
/*.slick-prev:before { content: "\2190"; }*/

.slick-next { right: 15px; bottom: 15px;background-position: -68px -68px; }
/*.slick-next:before { content: "\2192"; }*/

/* Dots */
.slick-slider { margin-bottom: 30px; }

.slick-dots { position: absolute; bottom: 15px; list-style: none; display: block; text-align: center; padding: 0px; right: 85px; }
.slick-dots li { position: relative; display: inline-block; height: 10px; width: 10px; margin: 0px 2px; padding: 0px; cursor: pointer; }
.slick-dots li button { border: 0; background: transparent; display: block; height: 10px; width: 10px; outline: none; line-height: 0; font-size: 0; color: transparent; padding: 0px; cursor: pointer; }
.slick-dots li button:focus { outline: none; }
.slick-dots li button:before { position: absolute; top: 0; left: 0; content: "\2022"; width: 20px; height: 20px; font-family: "slick"; font-size: 6px; line-height: 20px; text-align: center; color: #fff; opacity: 0.4; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.slick-dots li.slick-active button:before { opacity: 0.9; }
