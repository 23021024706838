/* <input-star></input-star> directive */
.angular-input-stars {
	
	display: inline-block;
	padding: 0;
	/*margin-left: -5px;*/
	list-style: none;

}

.angular-input-stars > li {
		
	font-size: 14px;
	color: #dddddd;
	display: inline-block;
	/*padding-right: 5px;*/
	/*padding-left: 5px;*/
	cursor: pointer;

}

.angular-input-stars > li i.readonly {
	cursor: not-allowed;
}

.angular-input-stars > li .active {

		color: #E7711B;

		/*text-shadow: black 0 0 1px, 0 0 1px ;	*/

}

.angular-input-stars > li .active.angular-input-stars-hover {
	/*text-shadow: black 0 0 3px;*/
}
